import { createMuiTheme } from '@material-ui/core/styles'

// eslint-disable-next-line import/no-unresolved
import { disableRipple, mainColor, secondaryColor } from '../styles/variables.module.scss'
import { colorToHex } from '../utils/color-to-hex'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: colorToHex(mainColor),
    },
    secondary: {
      main: colorToHex(secondaryColor),
    },
  },
  props: {
    MuiButton: {
      color: 'inherit',
      style: {
        textTransform: 'none',
      },
      disableRipple: disableRipple === 'true',
    },
    MuiList: {
      dense: true,
    },
    MuiTable: {
      size: 'small',
    },
    MuiCheckbox: {
      color: 'primary',
    },
    MuiRadio: {
      color: 'primary',
    },
    MuiSwitch: {
      color: 'primary',
    },
  },
  typography: {
    // useNextVariants: true
  },
})

export default theme
